import React, {useState, useEffect} from "react"
import axios from "axios"
import NewPost from "./NewPost"
import PostItem from "./PostItem"

async function getPosts(page) {
    const endpoint = `${process.env.GATSBY_ISTNOTYOU_GET_ALL}?page=${page}`;
    const result = await axios.get(endpoint)
    const res = await result.data;
    return res;
}

const Posts = ({posts, isLoading, location, ...props}) => {

    return (
        isLoading ?
        <div className="uk-flex uk-flex-center uk-flex-middle">
            <h1 data-uk-spinner />
        </div>
        :
        <div className="uk-grid uk-grid-medium uk-grid-row-large uk-child-width-1-2@s" data-uk-grid>
            {posts.map((post, i) => <PostItem key={i} post={post} location={location} isLoading={isLoading} />)}
        </div>
    )
}

export default Posts
