import React, {useState, useEffect} from "react"
import axios from "axios"
import localForage from 'localforage'
import Layout from "../components/layout"
import Logout from "../components/Form/Logout"
import Posts from "../components/Posts"
import NewPost from "../components/Posts/NewPost"
import SEO from "../components/seo"

async function getPosts(page) {
    const endpoint = `${process.env.GATSBY_ISTNOTYOU_GET_ALL}`;
    const result = await axios.get(endpoint)
    const res = await result.data;
    return res;
}

const Index = ({location, ...props}) => {
    const [preload, setPreload] = useState(true);
    const [posts, setPosts] = useState([])
    const [post, setPost] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [newPost, setNewPost] = useState({})
    const [page, setPage] = useState(0)
    const [authenticated, setAuthenticated] = useState(false)

    useEffect(
        () => {
            const token = localStorage.auth
            token && token.length ? setAuthenticated(true) : setAuthenticated(false)

            let data = []
            localForage.getItem('postData')
            .then(res => {
                if(!res) {
                    console.log("NO DATA")
                    setIsLoading(true)
                }
                data = res ? res : []
                setPosts(data)
                setIsLoading(false)
            })
        }, []
    )

    useEffect(
        () => {
            getPosts(page)
            .then(response => {
                const posts = response.map(data => data.data)
                const data = response.sort((a, b) => new Date(parseInt(b.data.timestamp)).getTime() - new Date(parseInt(a.data.timestamp)).getTime());
                // const prev = response.prev;
                // const next = response.next;
                localForage.setItem('postData', data)
                .then(res => {
                    setPosts(data)
                    setIsLoading(false)
                })
            })
            .catch(err => {
                console.log("GET ERROR RESPONSE", err)
            })
        }, [page]
    )

    useEffect(
        () => {
            if(!posts.length) return;
            setPosts([newPost, ...posts])
        }, [newPost]
    )

    return (
        <Layout location={location}>
            <SEO title={"Make a Mask Meme"} pathname={location.pathname} />
            <section className="uk-section uk-section-small uk-visible@m">
                <div className="uk-container uk-container-xsmall">
                    <h1>There is no USA<br />without US.</h1>
                </div>
                <div className="uk-margin-medium-top">
                    <NewPost setNewPost={setNewPost} />
                </div>
            </section>
            <section className="uk-section uk-padding-small-top uk-margin-xlarge-bottom">
                <div className="uk-container uk-container-small">
                    {authenticated ?
                        <div className="uk-width-1-1 uk-padding-small">
                            <Logout />
                        </div>
                        :null
                    }
                    <Posts posts={posts} isLoading={isLoading} location={location} />
                </div>
            </section>
        </Layout>
    )
}

export default Index
